module.exports = {
  title: 'Blog', // Required
  author: 'Guillermo Portas', // Required
  description: 'Software Engineer',
  primaryColor: '#272341', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://gportas.me',
    github: 'https://github.com/gportas',
    linkedin: 'https://linkedin.com/in/gportas/',
  },
  pathPrefix: '',
  siteUrl: 'https://blog.gportas.me',
};
